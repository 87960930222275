import { useEffect, useRef, useState } from 'react'
import { Label, Input, Card, ColorChooser, Colors, SwipeActions, ActionSheet, SwipeActionProvider, ModalForm } from '../../components'
import { ActionSheetButton } from '../../components/ActionSheet'
import { Section, Navbar, SectionContent, Topbar } from '../../components/section'
import API from '../../services/API'
import VaultHandler from '../../services/VaultHandler'
import * as JsSearch from 'js-search'
import { faArrowRightFromBracket, faArrowRightToBracket, faCheck, faChevronDown, faChevronRight, faCircle, faCopy, faFolder, faFolderPlus, faLock, faMoneyCheck, faNoteSticky, faPencil, faPlus, faThumbTack, faTrashCan, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import KeyRequest from './KeyRequest'
import NewFolder from './NewFolder'
import EditFolder from './EditFolder'
import NewPassword from './NewPassword'
import EditPassword from './EditPassword'

const folderIcons = {'fas fa-folder': faFolder, 'fas fa-trash-can': faTrashCan}

function FolderTreeItem({identifier, level, data, moveFolderMode = false, movePasswordMode = false, onSelect = () => {}, selectionPath = null, locked = false, navigate, containerClass, contentClass, moveFolder, editFolder, deleteFolder, setCallback, onDrag}){
	const [expanded, setExpanded] = useState(!locked)

	function comparePaths(path1, path2){
		if (path1.length !== path2.length) return false
		for (let i = 0; i < path1.length; i++) if (path1[i].name !== path2[i].name || path1[i].type !== path2[i].type) return false
		return true
	}

	const isParentFolder = selectionPath ? comparePaths(data.path, selectionPath.slice(moveFolderMode ? 0 : 1, -1)) : false
	if (moveFolderMode && selectionPath && comparePaths(data.path, selectionPath)) return null //Hide selected folder on moveFolderMode

	function onClick(){
		if (moveFolderMode || movePasswordMode){
			if (!isParentFolder) onSelect(data.path)
		} else {
			navigate(data.path)
		}
	}

	function handleChevronClick(e){
		if (!locked){
			e.stopPropagation()
			setExpanded(e => !e)	
		} else {
			onClick()
		}
	}

	return <>
		<SwipeActions
			identifier={identifier}
			containerClass={containerClass}
			contentClass={contentClass}
			height={44}
			paddingLeft={15 * level}
			buttonWidth={80}
			onClick={onClick}
			swipeLocked={data.permanent}
			setCallback={setCallback}
			onDrag={onDrag}
			rightButtons={[
				{
					color: 'var(--blue)',
					icon: faPencil,
					onClick: () => { editFolder(data.path) }
				},
				{
					color: 'var(--purple)',
					icon: faFolder,
					onClick: () => { moveFolder(data.path) }
				},
				{
					color: 'var(--red)',
					icon: faTrashCan,
					onClick: (index, button) => { deleteFolder(data.path, index, button) }
				}
			]}
		>
			<FontAwesomeIcon icon={folderIcons[data.icon]} className={`passwords__list__folder__icon ${movePasswordMode && isParentFolder ? 'disabled' : ''}`}/>
			<div className={`passwords__list__folder__content__horizontal-wrapper ${movePasswordMode || moveFolderMode ? 'select-mode' : ''}`}>
				<p className={`passwords__list__folder__title ${movePasswordMode && isParentFolder ? 'disabled' : ''}`}>{data.name}</p>
				{
					moveFolderMode ? (
						isParentFolder ? <FontAwesomeIcon icon={faCheck} style={{color: 'var(--theme-color)'}}/> : null
					) :
					<>
						{!movePasswordMode ? <p className='passwords__list__folder__size'>{data.passwords.length + (data.type === 'trash' ? data.folders.length : 0)}</p> : null}
						<FontAwesomeIcon icon={data.folders.length > 0 && expanded ? faChevronDown : faChevronRight} className='passwords__list__folder__chevron' style={{color: data.folders.length > 0 && !locked ? 'var(--theme-color)' : '#c0c1c3'}} onClick={handleChevronClick}/>	
					</>
				}
			</div>
		</SwipeActions>

		{
			data.folders.length > 0 && expanded ?
			data.folders.map((folder, i) => (
				<FolderTreeItem
					key={folder.name}
					identifier={`${identifier}_${i}`}
					containerClass={containerClass}
					contentClass={contentClass}
					level={level + 1}
					data={folder}
					movePasswordMode={movePasswordMode}
					moveFolderMode={moveFolderMode}
					onSelect={onSelect}
					selectionPath={selectionPath}
					navigate={navigate}
					moveFolder={moveFolder}
					editFolder={editFolder}
					deleteFolder={deleteFolder}
					setCallback={setCallback}
					onDrag={onDrag}
				/>
			)) : null
		}
	</>
}

function FolderTree({data, showTrash = false, collapsed = false, movePasswordMode = false, moveFolderMode = false, onSelect, selectionPath, navigate, moveFolder, editFolder, deleteFolder, setCallback, onDrag}){
	return <>
		<Label primary>Carpetas</Label>
		<div className='passwords__list'>
			{
				moveFolderMode ?
				<FolderTreeItem
					identifier={0}
					level={1}
					data={{
						type: 'folder',
						name: 'Todas las carpetas',
						icon: 'fas fa-folder',
						folders: data.folders,
						path: [{type: 'root'}]
					}}
					moveFolderMode={moveFolderMode}
					onSelect={onSelect}
					selectionPath={selectionPath}
					locked={false}
					navigate={navigate}
					moveFolder={moveFolder}
					deleteFolder={deleteFolder}
					containerClass='passwords__list__folder'
					contentClass='passwords__list__folder__content'
					buttonWidth={80}
					setCallback={setCallback}
					onDrag={onDrag}
				/> :
				data.folders
				.concat(showTrash ? [data.trash] : [])
				.map((folder, i) => (
					<FolderTreeItem
						key={folder.name}
						identifier={i}
						level={1}
						data={folder}
						movePasswordMode={movePasswordMode}
						moveFolderMode={moveFolderMode}
						onSelect={onSelect}
						selectionPath={selectionPath}
						locked={folder.type === 'trash' || collapsed}
						navigate={navigate}
						moveFolder={moveFolder}
						editFolder={editFolder}
						deleteFolder={deleteFolder}
						containerClass='passwords__list__folder'
						contentClass='passwords__list__folder__content'
						buttonWidth={80}
						setCallback={setCallback}
						onDrag={onDrag}
					/>
				))
			}
		</div>
	</>
}

function PasswordList({title, passwords, navigate, pinPassword, movePassword, deletePassword, setCallback, onDrag}){
	return <>
		<Label primary>{title}</Label>
		<div className='passwords__list'>
			{
				passwords.map((password, i) => {
					const updatedDate = new Date(password.updatedTimestamp)

					return (
						<SwipeActions
							key={password.name}
							containerClass='passwords__list__password'
							contentClass='passwords__list__password__content'
							buttonWidth={60}
							height={56}
							setCallback={setCallback}
							onDrag={onDrag}
							onClick={() => {
								navigate(password.path)
							}}
							leftButtons={password.path[1].type === 'trash' ? [] : [
								{
									color: `var(--dark-${password.pinned ? 'red' : 'yellow'})`,
									icon: faThumbTack,
									onClick: () => { pinPassword(password.path) }
								}
							]}
							rightButtons={[
								{
									color: 'var(--purple)',
									icon: faFolder,
									onClick: () => { movePassword(password.path) }
								},
								{
									color: 'var(--red)',
									icon: faTrashCan,
									onClick: (index, button) => { deletePassword(password.path, index, button) }
								}
							]}
						>
						
							<div className='passwords__list__password__horizontal-wrapper'>
								<p className='passwords__list__password__horizontal-wrapper__title'>{password.name}</p>
								<div className='.passwords__list__password__horizontal-wrapper__tags'>
									{password.tags.map(tag => (
										<FontAwesomeIcon key={tag} icon={faCircle} style={{fontSize: 15, color: tag}}/>
									))}
								</div>
								<p className='passwords__list__password__horizontal-wrapper__date'>{`${updatedDate.getDate()}/${updatedDate.getMonth()+1}/${updatedDate.getFullYear()}`}</p>
							</div>
							<p style={{color: 'var(--secondary-text-color)'}}>{password.user || 'N/A'}</p>
						</SwipeActions>
					)
				})
			}
		</div>
	</>
}

function FolderView({data, navigate, movePassword, deletePassword, moveFolder, editFolder, deleteFolder, pinPassword, setCallback, onDrag}){
	const pinnedPasswords = data.passwords?.filter(password => data.type !== 'trash' && password.pinned) || []
	const unpinnedPasswords = data.passwords?.filter(password => data.type === 'trash' || !password.pinned) || []

	return <>
		{
			data.folders?.length > 0 ? 
			<FolderTree
				data={data}
				collapsed
				navigate={navigate}
				moveFolder={moveFolder}
				editFolder={editFolder}
				deleteFolder={deleteFolder}
				setCallback={setCallback}
				onDrag={onDrag}
			/> : null
		}
		{
			(pinnedPasswords.length > 0) ?
			<PasswordList
				title='Contraseñas fijadas'
				passwords={pinnedPasswords}
				navigate={navigate}
				pinPassword={pinPassword}
				movePassword={movePassword}
				deletePassword={deletePassword}
				setCallback={setCallback}
				onDrag={onDrag}
			/> : null
		}
		{
			unpinnedPasswords.length > 0 ?
			<PasswordList
				title='Contraseñas'
				passwords={unpinnedPasswords}
				navigate={navigate}
				pinPassword={pinPassword}
				movePassword={movePassword}
				deletePassword={deletePassword}
				setCallback={setCallback}
				onDrag={onDrag}
			/> : null
		}
		{
			data.folders.length + data.passwords.length === 0 ?
			<div className='empty-folder-wrapper'>
				<FontAwesomeIcon icon={faFolder} style={{color: '#878787', fontSize: 100 }} />
				<p style={{textAlign: 'center', color: '#878787', fontSize: 25}}>Carpeta vacía</p>
			</div> : null
		}
	</>
}

function PasswordView({data}){
	const [userNameClipboard, setUserNameClipboard] = useState(false);
	const [passwordClipboard, setPasswordClipboard] = useState(false);	

	function copyToClipboard(text, func){
		navigator.clipboard.writeText(text);
		func(true);
		setTimeout(() => {func(false)}, 2000);
	}

	return (<>
		{
			data.user !== '' ? <>
				<Label primary>Usuario</Label>
				<Input disabled icon={faUser} value={data.user} backgroundColor="white" buttons={[<FontAwesomeIcon icon={userNameClipboard ? faCheck : faCopy} onClick={() => {copyToClipboard(data.user, setUserNameClipboard)}}/>]} />		
			</> : null
		}				

		{
			data.password !== '' ? <>
				<Label primary>Contraseña</Label>
				<Input disabled type="password" icon={faLock} backgroundColor="white" value={data.password} buttons={[<FontAwesomeIcon icon={passwordClipboard ? faCheck : faCopy} onClick={() => {copyToClipboard(data.password, setPasswordClipboard)}}/>]} />		
			</> : null
		}
		
		{
			data.note !== '' ? <>
				<Label primary>Nota</Label>
				<Input disabled icon={faNoteSticky} type="text" backgroundColor="white" value={data.note} />
			</> : null
		}

		{
			data.tags.length > 0 ? <>
				<Label primary>Etiquetas</Label>
				<Card>
					<div style={{display: 'flex', gap: 10}}>
						{data.tags.map(tag => (
							<FontAwesomeIcon key={tag} icon={faCircle} style={{fontSize: 25, color: tag}}/>
						))}
					</div>
				</Card>
			</> : null
		}
	</>);
}

function SearchView({vault, search = '', navigate, editFolder, moveFolder, deleteFolder, movePassword, deletePassword, pinPassword, setCallback, onDrag}){
	const [searchTags, setSearchTags] = useState([])
	const [objects, setObjects] = useState([])
	const [resultsFolder, setResultsFolder] = useState({folders: [], passwords: [], pinnedPasswords: []})

	function getPathString(path){
		let string = '/'
		path.forEach(item => {
			if (item.type === 'folder') string += item.name + '/'
			else if (item.type === 'password') string += item.name
		})
		return string
	}

	function getFolderData(folder){
		return {...folder, type: 'folder'}
	}

	function getObjects(folder){
		let res = [{
			type: 'folder',
			pathString: getPathString(folder.path),
			data: getFolderData(folder)
		}]
		folder.folders.forEach(f => {
			res = res.concat(getObjects(f))
		})
		folder.passwords.forEach(p => {
			res = res.concat({
				type: 'password',
				pathString: getPathString(p.path),
				data: p
			})
		})
		return res
	}

	function filterObjects(documents){
		if (documents.length > 0){
			let newObjects = []
			if (search !== ''){
				let searchObject = new JsSearch.Search('pathString');
				searchObject.addIndex(['data', 'name'])
				searchObject.addDocuments(documents)
				newObjects = searchObject.search(search)
			} else {
				if (searchTags.length > 0){
					newObjects = documents.filter(o =>
						o.type === 'password' &&
						searchTags.map(tag => o.data.tags.includes(tag)).includes(true)
					)
				}
			}

			setResultsFolder({
				folders: newObjects.filter(o => o.type === 'folder').map(f => ({...f.data})),
				passwords: newObjects.filter(o =>
					o.type === 'password' &&
					(
						searchTags.length === 0 ||
						searchTags.some(tag => o.data.tags.includes(tag))
					)
				).map(p => ({...p.data})),
				
			})
		}
	}

	useEffect(() => {
		let newObjects = []
		vault.folders.forEach(f => {
			newObjects = newObjects.concat(getObjects(f))
		})
		setObjects(newObjects)
		filterObjects(newObjects)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vault])

	useEffect(() => {
		filterObjects(objects)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, searchTags])

	return <>
		<Card><ColorChooser style={{marginBottom: 0}} multiple value={searchTags} onChange={setSearchTags} colors={Colors} /></Card>
		<FolderView
			data={resultsFolder}
			type='folder'
			navigate={navigate}
			movePassword={movePassword}
			deletePassword={deletePassword}
			moveFolder={moveFolder}
			editFolder={editFolder}
			deleteFolder={deleteFolder}
			pinPassword={pinPassword}
			setCallback={setCallback}
			onDrag={onDrag}
		/>
	</>
}

export default function Passwords(){
	const [search, setSearch] = useState('')
	const [searchMode, setSearchMode] = useState(false)
	const [vault, setVault] = useState(null)
	const [target, setTarget] = useState(null)
	const [mode, setMode] = useState('view') //view, editPassword, newPassword, editFolder, newFolder...
	const [path, setPath] = useState([{type: 'root'}])
	const [editFolderPath, setEditFolderPath] = useState()

	const swipeListRef = useRef()

	const [moveObjectModalData, setMoveObjectModalData] = useState()
	const moveObjectModalRef = useRef()

	const [deleteActionSheetData, setDeleteActionSheetData] = useState()
	const deleteActionSheetRef = useRef()

	function calculateTarget(newPath = path){
		if (vault) setTarget(VaultHandler.find(vault, newPath))
	}

	function navigate(newPath){
		setMode('view')
		setSearchMode(false)
		setPath(newPath)
		calculateTarget(newPath)
	}

	function goBack(){
		if (path.length > 1) navigate(path.slice(0, -1))
	}

	function sharePassword(){
		if (path[path.length-1].type === 'password' && navigator.share){
			navigator.share({
				title: target.name,
				/*Indentation break is intentional*/
				text: `${target.name}
${target.user !== '' ? `Usuario: ${target.user}` : ''}
${target.password !== '' ? `Contraseña: ${target.password}` : ''}
${target.note !== '' ? `Nota: ${target.nota}` : ''}`
			})
		}
	}

	function newPassword(data){
		return new Promise((resolve, reject) => {
			VaultHandler.newPassword(vault, path, data).then(newVault => {
				resolve()
				handleSetVault(newVault)
			}).catch(() => {
				reject()
			})
		})
	}

	function updatePassword(data, nameChanged){
		return new Promise((resolve, reject) => {
			VaultHandler.updatePassword(vault, path, data, nameChanged).then(newVault => {
				resolve()
				handleSetVault(newVault)
				navigate(path.slice(0, -1).concat({type: 'password', name: data.name}))
			}).catch(() => {
				reject()
			})
		})
	}

	function movePassword(path){
		handleSetVault(VaultHandler.movePassword(vault, moveObjectModalData.path, path))
		moveObjectModalRef.current.close()
	}

	function handleMovePassword(passwordPath){
		setMoveObjectModalData({
			type: 'password',
			password: VaultHandler.find(vault, passwordPath),
			path: passwordPath
		})
		moveObjectModalRef.current.open()
	}

	function deletePassword(passwordPath){
		const newVault = VaultHandler.deletePassword(vault, passwordPath)
		handleSetVault(newVault)
		deleteActionSheetRef.current.close()
		if (path.length === 2 && path[1].type === 'trash' && newVault.trash.folders.length + newVault.trash.passwords.length === 0) goBack()
	}

	function handleDeletePassword(passwordPath, index, button){
		if (passwordPath[1].type === 'trash'){
			setDeleteActionSheetData({type: 'password', path: passwordPath})
			swipeListRef.current.expand(index, button)
			deleteActionSheetRef.current.open()
		} else deletePassword(passwordPath)
	}

	function newFolder(data){
		return new Promise((resolve, reject) => {
			VaultHandler.newFolder(vault, path, data).then(newVault => {
				resolve()
				handleSetVault(newVault)
			}).catch(() => {
				reject()
			})
		})
	}

	function editFolder(data, nameChanged){
		return new Promise(async (resolve, reject) => {
			VaultHandler.updateFolder(vault, editFolderPath, data, nameChanged).then(newVault => {
				resolve()
				handleSetVault(newVault)
			}).catch(() => {
				reject()
			})
		})
	}

	function moveFolder(path){
		handleSetVault(VaultHandler.moveFolder(vault, moveObjectModalData.path, path))
		moveObjectModalRef.current.close()
	}

	function handleMoveFolder(folderPath){
		setMoveObjectModalData({
			type: 'folder',
			folder: VaultHandler.find(vault, folderPath),
			path: folderPath
		})
		moveObjectModalRef.current.open()
	}

	function handleEditFolder(path){
		setEditFolderPath(path)
		setMode('editFolder')
	}

	function deleteFolder(folderPath){
		const newVault = VaultHandler.deleteFolder(vault, folderPath)
		handleSetVault(newVault)
		deleteActionSheetRef.current.close()
		if (path.length === 2 && path[1].type === 'trash' && newVault.trash.folders.length + newVault.trash.passwords.length === 0) goBack()
	}

	function handleDeleteFolder(folderPath, index, button){
		if (folderPath[1].type === 'trash'){
			setDeleteActionSheetData({type: 'folder', path: folderPath})
			swipeListRef.current.expand(index, button)
			deleteActionSheetRef.current.open()
		} else deleteFolder(folderPath)
	}

	function handleImportPasswords(){
		const data = prompt('Ingrese los datos')
		if (data && data.length > 0){
			try {
				handleSetVault(VaultHandler.importPasswords(vault, path, JSON.parse(data)))
			} catch(e){
				alert('Datos inválidos')
			}
		}
	}

	function pinPassword(passwordPath){
		const newVault = VaultHandler.pinPassword(vault, path.concat(passwordPath))
		handleSetVault(newVault)
	}

	function handleSetVault(newVault){
		newVault.folders.forEach(folder => {VaultHandler.calculatePaths([{type: 'root'}], folder)})
		VaultHandler.calculatePaths([{type: 'root'}], newVault.trash)
		setVault(newVault)
		API.UpdatePasswords(VaultHandler.getEncryptedVault(newVault))
		setMode('view')
	}

	useEffect(() => {
		calculateTarget()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vault])

	return (
		vault === null ? <KeyRequest setVault={handleSetVault} /> :
		target === null ? null :
		mode === 'newPassword' ? <NewPassword newPassword={newPassword} cancel={() => {setMode('view')}} /> :
		mode === 'editPassword' ? <EditPassword data={target} updatePassword={updatePassword} cancel={() => {setMode('view')}} /> :
		mode === 'newFolder' ? <NewFolder newFolder={newFolder} cancel={() => {setMode('view')}}/> :
		mode === 'editFolder' ? <EditFolder data={VaultHandler.find(vault, editFolderPath)} editFolder={editFolder} cancel={() => {setMode('view')}} /> :
		<Section>
			<Topbar 
				title={path[path.length-1].type === 'root' ? 'Contraseñas' : target.name}
				onBack={path[path.length-1].type === 'root' ? null : goBack}
				search
				searchValue={search}
				onSearch={setSearch}
				searchMode={searchMode}
				setSearchMode={setSearchMode}
			>
				{
					path[path.length-1].type === 'root' ? <FontAwesomeIcon icon={faFolderPlus} className='topbar__buttons__button' onClick={() => {setMode('newFolder')}}/> :
					path[path.length-1].type === 'folder' ? <>
						<FontAwesomeIcon icon={faArrowRightToBracket} className='topbar__buttons__button' rotation={90} onClick={handleImportPasswords} />
						<FontAwesomeIcon icon={faFolderPlus} className='topbar__buttons__button' onClick={() => {setMode('newFolder')}}/>
						<FontAwesomeIcon icon={faPlus} className='topbar__buttons__button' onClick={() => {setMode('newPassword')}}/>
					</> :
					path[path.length-1].type === 'password' ? <>
						<FontAwesomeIcon icon={faArrowRightFromBracket} className='topbar__buttons__button' onClick={sharePassword}/>
						<FontAwesomeIcon icon={faPencil} className='topbar__buttons__button' onClick={() => {setMode('editPassword')}}/>
					</> :
					null
				}
			</Topbar>

			<SectionContent id='passwords'>
				<SwipeActionProvider ref={swipeListRef}>
					{
						searchMode ?
							<SearchView
								vault={vault}
								search={search}
								navigate={navigate}
								movePassword={handleMovePassword}
								deletePassword={handleDeletePassword}
								moveFolder={handleMoveFolder}
								editFolder={handleEditFolder}
								deleteFolder={handleDeleteFolder}
								pinPassword={pinPassword}
							/> :
						path[path.length-1].type === 'root' ?
							<FolderTree
								data={target}
								showTrash={vault.trash.folders.length + vault.trash.passwords.length > 0}
								navigate={navigate}
								moveFolder={handleMoveFolder}
								editFolder={handleEditFolder}
								deleteFolder={handleDeleteFolder}
							/> :
						['folder', 'trash'].includes(path[path.length-1].type) ?
							<FolderView
								data={target}
								navigate={navigate}
								movePassword={handleMovePassword}
								deletePassword={handleDeletePassword}
								moveFolder={handleMoveFolder}
								editFolder={handleEditFolder}
								deleteFolder={handleDeleteFolder}
								pinPassword={pinPassword}
							/> :
						path[path.length-1].type === 'password' ? <PasswordView data={target}/> : null
					}
				</SwipeActionProvider>
			</SectionContent>

			<Navbar sectionName='passwords'/>
				
			{
				moveObjectModalData ?
				<ModalForm
					reference={moveObjectModalRef}
					title={`Mover ${moveObjectModalData?.type === 'folder' ? 'carpeta' : 'password'}`}
					cancelTitle='Cancelar'
					sheetStyle={{
						height: 'calc(100% - 30px)'
					}}
				>
					<div className='move-object-modal__object'>
						<FontAwesomeIcon icon={moveObjectModalData.type === 'password' ? faMoneyCheck : faFolder} className='move-object-modal__object__icon'/>
						<div className='move-object-modal__object__vertical-wrapper'>
							<p className='move-object-modal__object__title'>{moveObjectModalData.type === 'password' ? moveObjectModalData.password.name : moveObjectModalData.folder.name}</p>
							<p className='move-object-modal__object__subtitle'>{moveObjectModalData.type === 'password' ? '1 contraseña' : `${moveObjectModalData.folder.passwords.length} contraseñas`}</p>
						</div>
					</div>
					<div style={{padding: 10}}>
						<FolderTree
							data={vault}
							movePasswordMode={moveObjectModalData.type === 'password'}
							moveFolderMode={moveObjectModalData.type === 'folder'}
							selectionPath={moveObjectModalData.path}
							onSelect={moveObjectModalData.type === 'password' ? movePassword : moveFolder}
						/>
					</div>
				</ModalForm> : null
			}	

			<ActionSheet
				reference={deleteActionSheetRef}
				title={`Se eliminará esta ${deleteActionSheetData?.type === 'password' ? 'contraseña' : 'carpeta'}. Esta ación no se puede deshacer.`}
				cancelTitle="Cancelar"
				onClose={() => {
					swipeListRef.current.resetSliders()
				}}
			>
				<ActionSheetButton title="Elminar" color='var(--dark-red)' onClick={() => {
					if (deleteActionSheetData?.type === 'password'){
						deletePassword(deleteActionSheetData.path)
					} else {
						deleteFolder(deleteActionSheetData.path)
					}
					deleteActionSheetRef.current.close()
				}} />
			</ActionSheet>
		</Section>
	)
}