import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Label, SwipeButton } from '../../components';
import { Navbar, SectionContent, Topbar } from '../../components/section';
import Section from '../../components/section/Section';
import Auth from '../../services/Auth';
import ReactSwitch from 'react-switch';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileScreen } from '@fortawesome/free-solid-svg-icons';

function Settings(props){
	const [signOutLoading, setSignOutLoading] = useState(false)
	const [tokenLoading, setTokenLoading] = useState(false)
	const [deviceIDLoading, setDeviceIDLoading] = useState(false)
	const [settings, setSettings] = useState(Auth.UserData.settings)

	const navigate = useNavigate()

	function handleSignOut(){
		setSignOutLoading(true)
		if (settings.deviceID.status !== 'active' || !thisDeviceIsAuthenticator || window.confirm('Cerrar sesión desactivará Device ID')){
			Auth.signOut().then(data => {
				navigate('/auth/signin')
			})
		} else {
			setSignOutLoading(false)
		}
	}

	function handleDeviceIDStateChange(checked){
		Auth.updateDeviceIDStatus(checked ? 'pending' : 'inactive').then(data => {
			setSettings(data.data)
		}).catch(e => {
			//alert('Solo se puede desactivar Device ID desde el dispositivo autenticador')
			toast('Solo se puede desactivar Device ID desde el dispositivo autenticador', {style: {backgroundColor: 'var(--red)', color: 'white'}})
		});
	}

	function handleSetDeviceAsAuthenticator(){
		setDeviceIDLoading(true)
		Auth.updateDeviceIDStatus('active').then(data => {
			setDeviceIDLoading(false)
			setSettings(data.data)
		}).catch(() => {})
	}

	function handleRemoveDeviceID(){
		setDeviceIDLoading(true)
		Auth.updateDeviceIDStatus('pending').then(data => {
			setDeviceIDLoading(false)
			setSettings(data.data)
		}).catch(() => {})
	}

	function handleGenerateToken(){
		setTokenLoading(true)
		Auth.getOTT().then(ott => {
			window.location.href = `https://accounts.zaifo.com.ar/?t=${ott}`
		})
	}

	const deviceIDIsActive = settings.deviceID.status === 'active';
	const thisDeviceIsAuthenticator = deviceIDIsActive && Auth.UserData.settings.deviceID.authenticatorHID === Auth.UserData.currentHID;
	const authenticatorDeviceName = deviceIDIsActive ? Auth.UserData.sessions.filter(s => s.HID === Auth.UserData.settings.deviceID.authenticatorHID)[0].deviceName : '';

	return (
		<Section>
			<Topbar title="Opciones" />

			<SectionContent id="settings">
				<div className='settings__list'>
					<Label primary>Seguridad</Label>
					<div className='settings__list__item'>
						<FontAwesomeIcon icon={faMobileScreen} className='settings__list__item__icon'/>
						<p className='settings__list__item__title'>Device ID</p>
						<ReactSwitch
							className="react-switch"
							width={50}
							onColor='#00d039'
							checkedIcon={false}
							uncheckedIcon={false}
							handleDiameter={24}
							activeBoxShadow={null}
							onChange={handleDeviceIDStateChange}
							checked={settings.deviceID.status !== 'inactive'}
						/>
					</div>
					{settings.deviceID.status === 'pending' ? <p style={{color: '#757575', marginBottom: 10}}>Device ID se activará cuando configures un dispositivo como autenticador</p> : null}
					{settings.deviceID.status === 'pending' ? <SwipeButton title="Usar este dispositivo para Device ID" onSubmit={handleSetDeviceAsAuthenticator} loading={deviceIDLoading}/> : null}
					{
					deviceIDIsActive ? 
						<p style={{color: 'var(--theme-color)', marginBottom: 10}}>
							{`Estás usando ${thisDeviceIsAuthenticator ? 'este dispositivo' : authenticatorDeviceName} como autenticador`}
						</p> : null
					}
					{
						thisDeviceIsAuthenticator ? <SwipeButton color="var(--red)" title="Dejar de usar este dispositivo para Device ID" onSubmit={handleRemoveDeviceID} loading={deviceIDLoading} /> : null
					}
				</div>
				<div style={{display: 'flex', flexFlow: 'column', gap: 10}}>
					<p style={{display: 'flex', justifyContent: 'flex-end', color: 'var(--secondary-text-color)'}}>Versión: 0.5.0</p>
					<SwipeButton color="var(--red)" title="Cerrar sesión" onSubmit={handleSignOut} loading={signOutLoading} />
					{ window.navigator.standalone ? null : <SwipeButton title="Crear token para instalación" onSubmit={handleGenerateToken} loading={tokenLoading}/>}
				</div>
			</SectionContent>

			<Navbar sectionName="settings" />
		</Section>
	);
}

export default Settings;