import ReactLoading from 'react-loading';

function LoadingPage(props){
	return (
		<div className="loading-page">
			<ReactLoading type="spin" color='var(--theme-color)' height="40px" width="40px"/>
		</div>
	);
}

export default LoadingPage;