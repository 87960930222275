import { Navigate, Route, Routes, useSearchParams} from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import DeviceID from './pages/auth/DeviceID';
import Login from './pages/auth/Login';
import NewDevice from './pages/auth/NewDevice';
import Register from './pages/auth/Register';
import EditDevice from './pages/home/EditDevice';
import EditProfile from './pages/home/EditProfile';
import MyAccount from './pages/home/MyAccount';
import Settings from './pages/settings/Settings';
import Passwords from './pages/passwords/Passwords';
import './styles/App.css';
import './styles/UI.css';
import { useEffect, useState } from 'react';
import Auth from './services/Auth';

function Token(){
	const [search] = useSearchParams()
	const token = search.get('t')
	const [redirect, setRedirect] = useState(null)

	if (redirect) return <Navigate to={redirect} />

	if (token === null) setRedirect('/auth/signin')		
	if (window.navigator.standalone){
		Auth.checkOTT(token).then(() => {
			setRedirect('/myaccount')
		}).catch(() => {
			setRedirect('/auth/signin')
		})
	} else {
		return (
			<div style={{display: 'grid', placeItems: 'center', height: '100%', fontSize: 24}}>
				Ya podés instalar la app
			</div>
		)
	}
}

export default function App() {
	useEffect(() => {
		document.body.addEventListener('scroll', (e) => {
			e.preventDefault()
			window.scrollTo(0, 0)
		}, {passive: false})
	}, [])

	return (
    	<Routes>
			<Route exact path="/" element={<Token />} />
			<Route exact path="/auth/signup" element={<Register />} />
			<Route exact path="/auth/signin" element={<Login />} />
			<Route exact path="/auth/newdevice" element={<NewDevice />} />
			<Route exact path="/deviceid" element={
				<RequireAuth redirectTo="/auth/signin">
					<DeviceID />
				</RequireAuth>
			} />
			<Route exact path="/myaccount" element={
				<RequireAuth redirectTo="/auth/signin">
					<MyAccount />
				</RequireAuth>
			} />
			<Route exact path="/myaccount/edit" element={
				<RequireAuth redirectTo="/auth/signin">
					<EditProfile />
				</RequireAuth>
			} />
			<Route exact path="/myaccount/device/edit" element={
				<RequireAuth redirectTo="/auth/signin">
					<EditDevice />
				</RequireAuth>
			} />
			<Route path="/passwords/*" element={
				<RequireAuth redirectTo="/auth/signin">
					<Passwords />
				</RequireAuth>
			} />
			<Route exact path="/settings" element={
				<RequireAuth redirectTo="/auth/signin">
					<Settings />
				</RequireAuth>
			} />
		</Routes>
  );
}
