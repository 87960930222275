import axios from 'axios';
import Auth from './Auth';

class API {
	constructor(){
		this.host = process.env.NODE_ENV === 'production' ? 'https://accounts.zaifo.com.ar' : 'http://localhost:8010';
	}

	UpdateProfile(data){
		return new Promise((resolve, reject) => {
			axios.post(`${this.host}/api/v2/user/profile`, data, {withCredentials: true}).then(res => {
				if (res.data.status === 'success'){
					Auth.UpdateUserProfile(res.data.data);
					resolve(res);
				} else {
					reject(res.data.data);
				}
			}).catch(e => {
				reject(e);
			})
		});
	}

	UpdateDevice(data){
		return new Promise((resolve, reject) => {
			axios.post(`${this.host}/api/v2/user/device/info`, data, {withCredentials: true}).then(res => {
				if (res.data.status === 'success'){
					Auth.UpdateSessions(res.data.data);
					resolve(res);
				} else {
					reject(res.data.data);
				}
			}).catch(e => {
				reject(e);
			})
		});
	}

	UpdatePasswords(data){
		Auth.UpdatePasswords(data);
		return new Promise((resolve, reject) => {
			axios.post(`${this.host}/api/v2/user/passwords`, data, {withCredentials: true}).then(res => {
				if (res.data.status === 'success'){
					resolve(res);
				} else {
					reject(res.data.data);
				}
			}).catch(e => {
				reject(e);
			})
		});
	}
}

export default new API();
