import React, {useState} from 'react';
import {Label, Button, Input} from '../../components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import Auth from '../../services/Auth';
import ReactCodeInput from 'react-code-input';
import { faDesktop, faMobileScreen } from '@fortawesome/free-solid-svg-icons';

const icons = {'fas fa-mobile-screen': faMobileScreen, 'fas fa-desktop': faDesktop}

function NewDevice(props){
	const [deviceName, setDeviceName] = useState('');
	const [deviceIcon, setDeviceIcon] = useState(isMobile ? 'fas fa-mobile-screen' : 'fas fa-desktop');
	const [code, setCode] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, ] = useState(null);

	const navigate = useNavigate();
	const [searchParams, ] = useSearchParams();

	function handleIconClick(){
		setDeviceIcon(deviceIcon === 'fas fa-mobile-screen' ? 'fas fa-desktop' : 'fas fa-mobile-screen');
	}

	function handleContinue(){
		if (deviceName !== ''){
			setLoading(true);
			Auth.signIn({
				authToken: Auth.AuthToken,
				userID: Auth.UserID,
				deviceName: deviceName,
				deviceIcon: deviceIcon,
				deviceIDToken: Auth.deviceID ? code : ''
				
			}, searchParams.get('service'), searchParams.get('continue')).then(data => {
				if (data.signedIn){
					if (data.redirectURL[0] === '/') navigate(data.redirectURL, {replace: true});
					else window.location.replace(data.redirectURL);
				} else {
					setLoading(false);
				}
			}).catch(e => {
				console.log(`Error al iniciar sesion: ${e}`);
			});
		}
	}

	function handleUseOtherAccount(){
		Auth.AuthToken = '';
		Auth.UserID = '';
		navigate(`/auth/signin?${searchParams.toString()}`, {replace: true});
	}

	return (
		<div id='login'>
			<p className='login-form__title'>Nuevo dispositivo</p>
			<p className='login-form__subtitle'>Acceso desde un nuevo dispositivo</p>
			
			<div className='login-form'>
				<Label>Nombre</Label>
				<Input autoFocus={true} spellCheck={false} value={deviceName} onChange={setDeviceName} icon={icons[deviceIcon]} placeholder="Nombre de este dispositivo" error={error?.type === 'name'} onIconClick={handleIconClick}/>
				{error?.type === 'name' ? <p className='input-error'>{error.msg}</p> : null}

				{
					Auth.deviceID ? (
						<>
							<Label>Device ID</Label>
							<ReactCodeInput
								className='react-code-input'
								inputStyle={{
									fontFamily: 'Source Sans Pro',
									marginRight: 10,
									height: 35,
									width: 35,
									border: 'solid 1px black !important',
									borderRadius: 8,
									fontSize: 22,
									textAlign: 'center'
								}}
								fields={6}
								value={code}
								type="number"
								onChange={setCode} />
							<p style={{color: 'var(--secondary-text-color)'}}>En el dispositivo autenticador entrá a la seccion "Device ID" y copiá el código</p>
						</>
					) : null
				}
			</div>

			<Button title="Continuar" disabled={deviceName === '' || (Auth.deviceID && code.length < 6)} loading={loading} onClick={handleContinue}/>
			
			<p className="login__use-other-account" onClick={handleUseOtherAccount}>Usar otra cuenta</p>
		</div>
	);
}

export default NewDevice;