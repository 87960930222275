import React from 'react';
import ColorButton from './ColorButton';

export default function ColorChooser({style, value, onChange = () => {}, colors, multiple = false}){
  function handleTagChange(c){
    if (multiple){
      if (value.includes(c)){
        //Remove
        onChange(value.filter(color => color !== c));
      } else {
        //Append
        onChange([...value, c]);
      }
    } else {
      onChange(c);
    }
  }

  return (
    <div className="color-chooser" style={style}>
      {colors.map((c, i) => <ColorButton key={i} selected={multiple ? value.includes(c) : (value === c)} color={c} onClick={handleTagChange}/>)}
    </div>
  );
}