import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useEffect, useState} from 'react';

function IconButton({selected, icon, onClick}){
	return (
		<div className={`icon-chooser__button ${selected ? 'selected' : ''}`} onClick={onClick}>
			<FontAwesomeIcon icon={icon}/>
		</div>
	);
}

export default function IconChooser({value, onChange = () => {}, iconNames, icons}){
  const [iconName, setIconName] = useState(value);

  useEffect(() => {
    setIconName(value);
  }, [value]);

  function clickHandler(i){
    onChange(i);
  }

  return (
    <div className="icon-chooser">
      {iconNames.map((ic, index) => <IconButton key={index} selected={iconName === ic} icon={icons[index]} onClick={() => clickHandler(ic)}/>)}
    </div>
  );
}