import ActionSheetReact from 'actionsheet-react';
import { useState } from 'react';

export function ActionSheetButton({title, color = 'var(--theme-color)', backgroundColor = '#efeff0', onClick = () => {}}){
	const [clicked, setClicked] = useState(false);

	return (
		<div
			className='action-sheet__button'
			onClick={onClick}
			onTouchStart={() => {setClicked(true)}}
			onTouchEnd={() => {setClicked(false)}}
			style={{
				color: color,
				backgroundColor: backgroundColor,
				filter: clicked ? 'brightness(90%)' : 'none'
			}}
		>	
			{title}
		</div>
	);
}

export default function ActionSheet({reference, title = null, cancelTitle = null, onClose = () => {}, children}){
	function cancel(){
		reference.current.close();
		onClose();
	}

	return (
		<ActionSheetReact
			ref={reference}
			sheetStyle={{
				backgroundColor: "transparent",
				padding: 10,
				marginBottom: 20,
				boxSizing: 'border-box'
			}}
			bgStyle={{
				backgroundColor: "rgba(1, 1, 1, 0.2)"
			}}
			sheetTransition='transform 0.2s ease-in-out'
			bgTransition='opacity 0.2s ease-in-out, z-index 0.2s ease-in-out'
			onClose={onClose}
		>
			<div className='action-sheet__list'>
				{
					title !== null ?
					<div className='action-sheet__list__title'>
						{title}
					</div> : null
				}
				{children}
			</div>
			{cancelTitle !== null ? <ActionSheetButton title={cancelTitle} backgroundColor='white' onClick={cancel} /> : null}
		</ActionSheetReact>
	);
}