import { useState } from "react"
import { Form, Label, Input } from "../../components"
import { faFolder } from "@fortawesome/free-solid-svg-icons"

export default function EditFolder({data, editFolder, cancel}){
	const [loading, setLoading] = useState(false)
	const [name, setName] = useState(data.name)
	const [nameError, setNameError] = useState(false)

	function handleSubmit(){
		setLoading(true)
		editFolder({
			name: name
		}, name !== data.name).catch(() => {
			setNameError(true)
			setLoading(false)
		})
	}

	return (
		<Form
			title="Editar carpeta"
			saveTitle="Guardar cambios"
			onSubmit={handleSubmit}
			loading={loading}
			cancelTitle="Cancelar"
			onCancel={cancel}
			disabled={name === ''}
		>
			<Label primary>Nombre</Label>
			<Input icon={faFolder} backgroundColor='white' value={name} onChange={setName} placeholder="Nombre de la carpeta" />
			{ nameError ? <p style={{color: 'var(--red)'}}>Ese nombre ya fue usado</p> : null }
		</Form>
	)
}