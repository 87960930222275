import crypto from 'crypto-browserify';
import Auth from './Auth';

class VaultHandler {
	constructor(){
		this.algorithm = 'aes256';
		this.inputEncoding = 'utf8';
		this.ivlength = 16; //AES blocksize
		this.outputEncoding = 'hex';
		this.vaultKey = '';
	}

	decrypt(ciphertext){
		if (!ciphertext) return '';

		let components = ciphertext.split(':');
		let decipher = crypto.createDecipheriv(this.algorithm, this.key, Buffer.from(components.shift(), this.outputEncoding));
		return decipher.update(components[0], this.outputEncoding, this.inputEncoding) + decipher.final(this.inputEncoding);
	}

	encrypt(text){
		if (!text) return '';

		let iv = crypto.randomBytes(this.ivlength);
		let cipher = crypto.createCipheriv(this.algorithm, this.key, iv);
    	return iv.toString(this.outputEncoding) + ':' + cipher.update(text, this.inputEncoding, this.outputEncoding) + cipher.final(this.outputEncoding);
	}

	deriveKey(secret, salt) {
		return new Promise((resolve, reject) => {
			crypto.pbkdf2(secret, salt, 100000, 32, 'sha512', (err, derivedKey) => {
				if (err) reject(err);
				resolve(derivedKey.toString('hex'));
			});
		});
	}

	decryptPassword(password){
		return {
			...password,
			name: this.decrypt(password.name),
			user: this.decrypt(password.user),
			password: this.decrypt(password.password),
			note: this.decrypt(password.note)
		}
	}

	encryptPassword(password){
		return {
			...password,
			name: this.encrypt(password.name),
			user: this.encrypt(password.user),
			password: this.encrypt(password.password),
			note: this.encrypt(password.note)
		}
	}

	decryptFolder(folder){
		return {
			icon: folder.icon,
			name: folder.name,
			permanent: folder.permanent,
			type: folder.type,
			folders: folder.folders.map(folder => this.decryptFolder(folder)),
			passwords: folder.passwords.map(password => this.decryptPassword(password)),
			deletedTimestamp: folder.deletedTimestamp
		};
	}

	encryptFolder(folder){
		return {
			icon: folder.icon,
			name: folder.name,
			permanent: folder.permanent,
			type: folder.type,
			folders: folder.folders.map(folder => this.encryptFolder(folder)),
			passwords: folder.passwords.map(password => this.encryptPassword(password)),
			deletedTimestamp: folder.deletedTimestamp
		};
	}

	generateVaultKey(password){
		return new Promise(async (resolve) => {
			this.vaultKey = await this.deriveKey(password, Auth.UserData.email);
			this.key = Buffer.from(this.vaultKey, 'hex');
			resolve();
		});
	}

	getDecryptedVault(vault){
		return {
			folders: vault.folders.map(folder => this.decryptFolder(folder)),
			trash: this.decryptFolder(vault.trash),
			cipherVersion: vault.cipherVersion
		}
	}

	getEncryptedVault(vault){
		let vaultClone = {...vault}
		const dateTime = new Date().getTime()
		vaultClone.trash.folders = vaultClone.trash.folders.filter(folder => (dateTime - new Date(folder.deletedTimestamp).getTime()) / (1000 * 3600 * 24) < 30)
		vaultClone.trash.passwords = vaultClone.trash.passwords.filter(password => (dateTime - new Date(password.deletedTimestamp).getTime()) / (1000 * 3600 * 24) < 30)

		return {
			folders: vaultClone.folders.map(folder => this.encryptFolder(folder)),
			trash: this.encryptFolder(vaultClone.trash),
			cipherVersion: vaultClone.cipherVersion
		}
	}

	find(vault, path){
		let target = null
		path.forEach((item, i) => {
			switch (item.type){
				case 'root':
					target = vault
					break
				case 'folder':
					target = target.folders.find(folder => folder.name === item.name)
					break
				case 'password':
					target = target.passwords.find(password => password.name === item.name)
					break
				case 'trash':
					target = target.trash
					break
				default:
					break
			}
		})

		return target
	}

	nameWithoutDuplicates(names, targetName){
		let available
		let suffix = 0
		do {
			available = true
			for (let i = 0; i < names.length; i++){
				if (names[i] === (suffix > 0 ? `${targetName} ${suffix}` : targetName)){
					available = false
					suffix++
					break
				}
			}
		} while(!available)
		return suffix > 0 ? `${targetName} ${suffix}` : targetName
	}

	newPassword(vault, path, data){
		return new Promise((resolve, reject) => {
			let vaultClone = {...vault}
			let parent = this.find(vaultClone, path)
			const index = parent.passwords.findIndex(password => password.name === data.name)
			if (index === -1){
				this.find(vaultClone, path).passwords.push(data)
				resolve(vaultClone)
			} else {
				reject()
			}
		})
	}

	updatePassword(vault, path, data, nameChanged){
		return new Promise((resolve, reject) => {
			let vaultClone = {...vault}
			let target = this.find(vaultClone, path)
			let index
			if (nameChanged){
				let parent = this.find(vaultClone, path.slice(0, -1))
				index = parent.passwords.findIndex(password => password.name === data.name)
			}
			if (!nameChanged || index === -1){
				target.name = data.name
				target.user = data.user
				target.password = data.password
				target.note = data.note
				target.tags = data.tags	
				resolve(vaultClone)
			} else {
				reject()
			}
		})
	}

	movePassword(vault, from, to){
		let vaultClone = {...vault}
		let parent = this.find(vaultClone, from.slice(0, -1))
		const index = parent.passwords.findIndex(password => password.name === from[from.length - 1].name)
		let toTarget = this.find(vaultClone, to)
		let passwordClone = {...parent.passwords[index]}
		passwordClone.name = this.nameWithoutDuplicates(toTarget.passwords.map(password => password.name), passwordClone.name)
		toTarget.passwords.push(passwordClone)
		parent.passwords.splice(index, 1)
		return vaultClone
	}

	deletePassword(vault, path){
		let vaultClone = {...vault}
		let target = this.find(vaultClone, path.slice(0, -1))
		const index = target.passwords.findIndex(password => password.name === path[path.length - 1].name)
		if (path[1].type !== 'trash'){
			let passwordClone = {...target.passwords[index]}
			passwordClone.deletedTimestamp = new Date()
			passwordClone.name = this.nameWithoutDuplicates(vaultClone.trash.passwords.map(password => password.name), passwordClone.name)
			passwordClone.pinned = false
			vaultClone.trash.passwords.push(passwordClone)
		}
		target.passwords.splice(index, 1)
		return vaultClone
	}

	getRecursiveFolderSize(target){
		let sum = 0
		target.folders.forEach(folder => {
			sum += this.getRecursiveFolderSize(folder)
		})
		sum += target.passwords.length
		return sum
	}

	newFolder(vault, path, data){
		return new Promise((resolve, reject) => {
			let vaultClone = {...vault}
			let parent = this.find(vaultClone, path)
			const index = parent.folders.findIndex(folder => folder.name === data.name)
			if (index === -1){
				parent.folders.push({
					name: data.name,
					icon: 'fas fa-folder',
					passwords: [],
					folders: [],
					permanent: false,
					type: 'folder'
				})
				resolve(vaultClone)
			} else {
				reject()
			}
		})
	}

	moveFolder(vault, from, to){
		let vaultClone = {...vault}
		let parent = this.find(vaultClone, from.slice(0, -1))
		const index = parent.folders.findIndex(folder => folder.name === from[from.length - 1].name)
		let toTarget = this.find(vaultClone, to)
		let folderClone = {...parent.folders[index]}
		folderClone.name = this.nameWithoutDuplicates(toTarget.folders.map(folder => folder.name), folderClone.name)
		toTarget.folders.push(folderClone)
		parent.folders.splice(index, 1)
		return vaultClone
	}

	updateFolder(vault, path, data, nameChanged){
		return new Promise((resolve, reject) => {
			let vaultClone = {...vault}
			let index
			if (nameChanged){
				let parent = this.find(vaultClone, path.slice(0, -1))
				index = parent.folders.findIndex(folder => folder.name === data.name)
			}
			if (!nameChanged || index === -1){
				let target = this.find(vaultClone, path)
				target.name = data.name
				resolve(vaultClone)
			} else {
				reject()
			}
		})
	}

	deleteFolder(vault, path){
		let vaultClone = {...vault}
		let target = this.find(vaultClone, path.slice(0, -1))
		const index = target.folders.findIndex(folder => folder.name === path[path.length - 1].name)
		if (path[1].type !== 'trash' && this.getRecursiveFolderSize(target.folders[index]) > 0){
			let folderClone = {...target.folders[index]}
			folderClone.deletedTimestamp = new Date()
			folderClone.name = this.nameWithoutDuplicates(vaultClone.trash.folders.map(folder => folder.name), folderClone.name)
			vaultClone.trash.folders.push(folderClone)
		}
		target.folders.splice(index, 1)
		return vaultClone
	}

	importPasswords(vault, path, data){
		const colors = {'red': '#fc5c65', 'orange': '#fd9644', 'yellow': '#fed330', 'green': '#26de81', 'turquoise': '#2bcbba', 'light-blue': '#45aaf2', 'blue': '#4b7bec', 'purple': '#a55eea', 'gray': '#778ca3'}
		const date = new Date()

		let vaultClone = {...vault}
		data.data.passwords.forEach(password => {
			this.find(vaultClone, path).passwords.push({
				name: password.name,
				user: password.user,
				password: password.password,
				note: '',
				tags: [colors[password.color]],
				updatedTimestamp: date
			})
		})
		return vaultClone
	}

	pinPassword(vault, path){
		let vaultClone = {...vault}
		const target = this.find(vaultClone, path)
		target.pinned = !target.pinned
		return vaultClone
	}

	generatePassword(){
		let result = ''
		let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!#$%&/-_*+'
		let charactersLength = characters.length
		for (let i = 0; i < 30; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength))
		return result
	}

	calculatePaths(parentPath, folder){
		const currentPath = parentPath.concat({type: folder.type, name: folder.name})
		folder.path = currentPath

		for (let i = 0; i < folder.passwords.length; i++){
			folder.passwords[i].path = currentPath.concat({type: 'password', name: folder.passwords[i].name})
		}

		folder.folders.forEach(folder => {
			this.calculatePaths(currentPath, folder)
		})
	}
}

export default new VaultHandler()