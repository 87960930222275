import { faEnvelope, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {Input, Label, Button} from '../../components';
import Auth from '../../services/Auth';
import '../../styles/Login.css';

function Register(props) {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const navigate = useNavigate();

	const afterRegister = async redir => {
		if (redir[0] === '/') navigate(redir, {replace: true});
		else window.location.replace(redir);
	};

	const register = () => {
		setLoading(true);
		setError(null);
		Auth.signUp({ name: name.trim(), email: email.trim().toLowerCase(), password: password }).then((data) => {
			setLoading(false);
			if (data.status === 'success'){
				afterRegister('/auth/newdevice');
			} else {
				setError(data.data);
			}
		}).catch(e => {
			console.log(`Error al registrar usuario: ${e}`);
		});
	};

	return (
		<div id='login'>
			<p className='login-form__title'>Crear cuenta</p>
			<p className='login-form__subtitle'>Ingresá los datos de tu nueva cuenta</p>
			
			<div className='login-form'>
				<Label>Nombre completo</Label>
				<Input autoFocus spellCheck={false} type="name"  value={name} onChange={setName} icon={faUser} placeholder="Ingresá tu nombre" />

				<Label>Email</Label>
				<Input spellCheck={false} type="email" value={email} onChange={setEmail} icon={faEnvelope} placeholder="Ingresá tu email" error={error?.type === 'email'}/>
				{error?.type === 'email' ? <p className='input-error'>{error.msg}</p> : null}

				<Label>Contraseña</Label>
				<Input type="password" value={password} onChange={setPassword} onEnter={register} icon={faLock} placeholder="Ingresá tu contraseña"/>
			</div>

			<Button title="Continuar" disabled={name === '' || email === '' || password === ''} loading={loading} onClick={register}/>
			<div className="login__suggestion_wrapper">
				<p className="login__suggestion">¿Ya tenés una cuenta?</p>
				<Link to="/auth/signin">Acceder</Link>
			</div>
		</div>
	);
}

export default Register;
