import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"

export default function SwipeActions({children, containerClass = '', contentClass = '', height = 40, buttonWidth = 40, paddingLeft = 15, leftButtons = [], rightButtons = [], onClick = () => {}, setCallback = () => {}, onDrag = () => {}, swipeLocked = false}){
	const [identifier, setIdentifier] = useState(null)
	const [offset, setOffset] = useState(0)
	const [previousCursorPos, setPreviousCursorPos] = useState(null)
	const [expandedButton, setExpandedButton] = useState(null)
	const [maxOffsetLeft, setMaxOffsetLeft] = useState(0)
	const [maxOffsetRight, setMaxOffsetRight] = useState(0)
	const [hMode, setHMode] = useState(null)
	const [vMode, setVMode] = useState(null)
	const [lastSwipeDirection, setLastSwipeDirection] = useState(null)

	const contentRef = useRef(null)
	const containerRef = useRef(null)

	function handleDragStart(pos){
		if (!swipeLocked){
			setPreviousCursorPos(pos)
		}
	}

	function handleDragMove(pos){
		if (!swipeLocked){
			const deltaX = pos.x - previousCursorPos.x
			const deltaY = pos.y - previousCursorPos.y

			if (vMode === null){
				//First swipe
				if (Math.abs(deltaX) > Math.abs(deltaY)){
					//Horizontal swipe
					if (hMode === null) setHMode(deltaX < 0 ? 'left' : 'right')
					setVMode(false)
				} else {
					//Vertical swipe
					setVMode(true)
				}
			} else {
				if (!vMode){
					//Horizontal swipe
					const newOffset = hMode === 'left' ? Math.min(Math.max(offset + deltaX, maxOffsetRight), 0) : Math.max(Math.min(offset + deltaX, maxOffsetLeft), 0)
					onDrag(identifier)
					setPreviousCursorPos(pos)
					setOffset(newOffset)
					setLastSwipeDirection(deltaX > 0 ? 'right' : 'left')
				}
			}
		}
	}

	function handleDragEnd(){
		if (vMode === false){
			if (hMode === 'left'){
				if (lastSwipeDirection === 'left' && offset < 0){
					setOffset(maxOffsetRight)
				} else {
					setOffset(0)
					setHMode(null)
				}
			} else if (hMode === 'right'){
				if (lastSwipeDirection === 'right' && offset > 0){
					setOffset(maxOffsetLeft)
				} else {
					setOffset(0)
					setHMode(null)
				}
			} 
		}
		setVMode(null)
	}

	function touchStart(e){
		handleDragStart({x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY})
	}

	function touchMove(e){
		handleDragMove({x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY})
	}

	function touchEnd(e){
		handleDragEnd()
	}

	function contextMenu(e){
		e.stopPropagation();
		e.preventDefault();
	}

	function handleClick(){
		if (!hMode) onClick()
		else {
			setOffset(0)
			setHMode(null)
		}
	}

	useEffect(() => {
		setMaxOffsetLeft(leftButtons.length * buttonWidth)
		setMaxOffsetRight(-rightButtons.length * buttonWidth)
		
		setIdentifier(setCallback((data) => {
			if (data.type === 'reset'){
				setExpandedButton(null)
				setOffset(0)
			} else if (data.type === 'expand'){
				setExpandedButton(data.button)
			}
		}))

		const refCopy = containerRef.current;
		let contextMenuEvent = refCopy.addEventListener('contextmenu', contextMenu, {passive: false})
		
		return () => {
			refCopy.removeEventListener('contextmenu', contextMenuEvent)
			setCallback(null, identifier)
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setCallback])

	const isAtMaxOffset = offset === (hMode === 'left' ? maxOffsetRight : maxOffsetLeft)

	return (
		<div
			ref={containerRef}
			className={`swipe-actions ${containerClass}`}
			style={{
				height: height,
				boxSizing: 'border-box'
			}}
			onTouchStart={touchStart}
			onTouchMove={touchMove}
			onTouchEnd={touchEnd}
			onClick={handleClick}
		>
			{
				leftButtons.map((button, i) => {
					const left = expandedButton === i ? 0 : offset / leftButtons.length * (leftButtons.length - i) - buttonWidth;

					return (
						<div
							key={i}
							className='swipe-actions__button'
							style={{
								position: 'absolute',
								left: left,
								backgroundColor: button.color,
								height: height,
								width: expandedButton === i ? '100%' : buttonWidth,
								transition: isAtMaxOffset || offset === 0 ? 'all 0.2s' : ''
							}}
							onClick={(e) => {
								e.stopPropagation()
								if (isAtMaxOffset) button.onClick(identifier, i)
							}}
						>
							<FontAwesomeIcon icon={button.icon}/>
						</div>
					);
				})
			}

			<div
				ref={contentRef}
				className={`swipe-actions__content ${contentClass}`}
				style={{
					height: height,
					left: offset,
					paddingLeft: paddingLeft,
					boxSizing: 'border-box',
					transition:isAtMaxOffset || offset === 0 ? 'all 0.2s' : ''
				}}
			>
				{children}
			</div>

			{
				rightButtons.map((button, i) => {
					const buttonIndex = i + leftButtons.length
					const right = expandedButton === buttonIndex ? 0 : -(offset / rightButtons.length * (rightButtons.length - i)) - buttonWidth;

					return (
						<div
							key={buttonIndex}
							className='swipe-actions__button'
							style={{
								position: 'absolute',
								right: right,
								backgroundColor: button.color,
								height: height,
								width: expandedButton === buttonIndex ? '100%' : buttonWidth,
								transition: isAtMaxOffset || offset === 0 ? 'all 0.2s' : ''
							}}
							onClick={() => {if (isAtMaxOffset || offset === 0 ? 'all 0.2s' : '') button.onClick(identifier, buttonIndex)}}
						>
							<FontAwesomeIcon icon={button.icon}/>
						</div>
					);
				})
			}
		</div>
	);
}