import ActionSheetReact from "actionsheet-react";

export default function ModalForm({children, sheetStyle, contentClass, reference, title = '', cancelTitle = '', onClose = () => {}}){
	function cancel(){
		reference.current.close();
		onClose();
	}

	return (
		<ActionSheetReact
			ref={reference}
			sheetStyle={sheetStyle}
			bgStyle={{
				backgroundColor: "rgba(1, 1, 1, 0.1)"
			}}
			sheetTransition='transform 0.2s ease-in-out'
			bgTransition='opacity 0.2s ease-in-out, z-index 0.2s ease-in-out'
			onClose={onClose}
		>
			<div className='modal-form__top'>
				<p className='modal-form__top__title'>{title}</p>
				{
					cancelTitle !== '' ?
					<p className='modal-form__top__cancel' onClick={cancel}>{cancelTitle}</p> : null
				}
			</div>
			<div className={contentClass}>
				{children}
			</div>
		</ActionSheetReact>
	)
}