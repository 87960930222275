import axios from 'axios';

class Auth {
	constructor(){
		this.authenticated = false;
		this.host = process.env.NODE_ENV === 'production' ? 'https://accounts.zaifo.com.ar' : 'http://localhost:8010';
		this.AuthToken = '';
		this.UserID = '';
		this.thisDeviceIsAuthenticator = false;
	}

	isAuthenticated(){
		return this.authenticated;
	}

	UpdateUserProfile(data){
		this.UserData.name = data.name;
		this.UserData.icon = data.icon;
	}

	UpdateSessions(data){
		this.UserData.sessions = data;
	}

	UpdatePasswords(data){
		this.UserData.passwords = data;
	}

	setUserData(data){
		this.UserData = data;
		if (data.settings) this.setUserSettings(data.settings);
	}

	setUserSettings(settings){
		this.UserData.settings = settings;
		this.thisDeviceIsAuthenticator = settings.deviceID.status === 'active' && settings.deviceID.authenticatorHID === this.UserData.currentHID;
	}

	checkSession(serviceParam, continueParam){
		return new Promise((resolve, reject) => {
			axios.get(`${this.host}/api/v2/auth/session?${serviceParam ? `service=${serviceParam}&` : ''}${continueParam ? `continue=${continueParam}&` : ''}`, {withCredentials: true}).then(res => {
				if (res.data.signedIn){
					this.authenticated = true
					this.deviceID = res.data.deviceID
					this.setUserData(res.data.userData)
				}
				resolve(res.data)
			})
		})
	}

	checkCredentials(data){
		return new Promise((resolve, reject) => {
			axios.post(`${this.host}/api/v2/auth/checkcredentials`, data, {withCredentials: true}).then(res => {
				if (res.data.status === 'success'){
					this.AuthToken = res.data.authToken;
					this.UserID = res.data.userID;
				}
				resolve(res.data);
			}).catch(e => {
				reject(e);
			});
		});
	}

	signIn(data, serviceParam, continueParam){
		return new Promise((resolve, reject) => {
			axios.post(`${this.host}/api/v2/auth/signin?${serviceParam ? `service=${serviceParam}&` : ''}${continueParam ? `continue=${continueParam}&` : ''}`, data, {withCredentials: true}).then(res => {
				if (res.data.signedIn){
					this.authenticated = true;
					this.setUserData(res.data.userData);
				}
				this.deviceID = res.data.deviceID;
				resolve(res.data);
			}).catch(e => {
				reject(e);
			});
		});
	}

	signUp(data){
		return new Promise((resolve, reject) => {
			axios.post(`${this.host}/api/v2/auth/signup`, data, {withCredentials: true}).then(res => {
				if (res.data.status === 'success'){
					this.AuthToken = res.data.data.authToken;
					this.UserID = res.data.data.userID;
				}
				resolve(res.data);
			}).catch(e => {
				reject(e);
			});
		});
	}

	signOut(HID = null){
		return new Promise((resolve, reject) => {
			axios.post(`${this.host}/api/v2/auth/signout`, {HID: HID || this.UserData.currentHID}, {withCredentials: true}).then(res => {
				if (res.data.status === 'success'){
					this.UserData.sessions = res.data.data;
					this.setUserSettings(this.UserData.settings); //We do this to update deviceID state
					if (HID === null || HID === this.UserData.currentHID) this.authenticated = false;
				}
				resolve(res.data);
			}).catch(e => {
				reject(e);
			});
		});
	}

	updateDeviceIDStatus(newStatus){
		return new Promise((resolve, reject) => {
			axios.post(`${this.host}/api/v2/user/deviceid/status`, {newStatus: newStatus}, {withCredentials: true}).then(res => {
				if (res.data.status === 'success'){
					this.setUserSettings(res.data.data);
					resolve(res.data);
				} else {
					reject();
				}
			}).catch(e => {
				reject(e);
			});
		});
	}

	getOTT(){
		return new Promise((resolve, reject) => {
			axios.get(`${this.host}/api/v2/auth/generateott`, {withCredentials: true}).then(res => {
				if (res.data.status === 'success'){
					resolve(res.data.data);
				} else {
					reject();
				}
			}).catch(e => {
				reject(e);
			});
		});
	}

	checkOTT(ott){
		return new Promise((resolve, reject) => {
			axios.post(`${this.host}/api/v2/auth/checkott`, {ott: ott}, {withCredentials: true}).then(res => {
				if (res.data.status === 'success'){
					this.authenticated = true
					this.setUserData(res.data.userData)
					this.deviceID = res.data.deviceID
					resolve()
				} else {
					reject()
				}
			}).catch(e => {
				reject(e)
			})
		})
	}
}

export default new Auth();
