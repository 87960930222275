import { useState } from "react"
import { Form, Label, Input, ColorChooser } from "../../components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMoneyCheck, faLock, faUser, faShuffle, faCheck, faCopy, faNoteSticky } from "@fortawesome/free-solid-svg-icons"
import VaultHandler from "../../services/VaultHandler"
import { Colors } from "../../components"

export default function EditPassword({data, updatePassword, cancel}){
	const [loading, setLoading] = useState(false)
	const [name, setName] = useState(data.name)
	const [user, setUser] = useState(data.user)
	const [password, setPassword] = useState(data.password)
	const [note, setNote] = useState(data.note)
	const [tags, setTags] = useState(data.tags)
	const [passwordClipboard, setPasswordClipboard] = useState(false)
	const [nameError, setNameError] = useState(false)

	function copyPasswordToClipboard(){
		navigator.clipboard.writeText(password)
		setPasswordClipboard(true)
		setTimeout(() => {setPasswordClipboard(false)}, 2000)
	}

	function handleSubmit(){
		setLoading(true)
		updatePassword({
			name: name,
			user: user,
			password: password,
			note: note,
			tags: tags,
			updatedTimestamp: new Date()
		}, name !== data.name).catch(() => {
			setLoading(false)
			setNameError(true)
		})
	}

	return (
		<Form
			title="Editar contraseña"
			saveTitle="Guardar cambios"
			onSubmit={handleSubmit}
			loading={loading}
			cancelTitle="Cancelar"
			onCancel={cancel}
			disabled={name === '' || (user === '' && password === '')}
		>
			<Label primary>Nombre</Label>
			<Input icon={faMoneyCheck} backgroundColor='white' value={name} onChange={setName} placeholder="Nombre del ítem" />
			{ nameError ? <p style={{color: 'var(--red)'}}>Ese nombre ya fue usado</p> : null }

			<Label primary>Usuario</Label>
			<Input icon={faUser} backgroundColor='white' value={user} onChange={setUser} placeholder="Usuario" />
			
			<Label primary>Contraseña</Label>
			<Input type="password" icon={faLock} backgroundColor='white' value={password} onChange={setPassword} placeholder="Contraseña" buttons={[<FontAwesomeIcon icon={faShuffle} onClick={() => {setPassword(VaultHandler.generatePassword())}}/>, <FontAwesomeIcon icon={passwordClipboard ? faCheck : faCopy} onClick={copyPasswordToClipboard}/>]} />

			<Label primary>Nota</Label>
			<Input icon={faNoteSticky} backgroundColor='white' value={note} onChange={setNote} placeholder="Nota adicional" />

			<Label primary>Etiquetas</Label>
			<ColorChooser multiple value={tags} onChange={setTags} colors={Colors} />
		</Form>
	);
}