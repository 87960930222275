import { useState } from "react";
import {Card, ColorChooser, Form, Input, Label, IconChooser, Colors} from "../../components";
import Auth from '../../services/Auth';
import { useNavigate } from "react-router-dom";
import API from "../../services/API";
import { faUser, faUserAstronaut, faUserGraduate, faUserNinja, faUserSecret } from "@fortawesome/free-solid-svg-icons";

function EditProfile(){
	const [name, setName] = useState(Auth.UserData.name);
	const [color, setColor] = useState(Auth.UserData.icon.color);
	const [icon, setIcon] = useState(Auth.UserData.icon.name);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	function save(){
		setLoading(true);
		API.UpdateProfile({name: name, icon: {name: icon, color: color}}).then(data => {
			navigate(-1);	
		});
	}

	function cancel(){
		navigate(-1);
	}

	return (
		<Form title="Editar perfil" saveTitle="Guardar cambios" onSubmit={save} loading={loading} cancelTitle="Cancelar" onCancel={cancel} disabled={name === ''}>
			<Label primary>Nombre</Label>
			<Input icon={faUser} backgroundColor="white" type="name" value={name} onChange={setName} placeholder="Ingresá tu nombre" />

			<Label primary>Imagen de perfil</Label>
			<Card>
				<Label>Color</Label>
				<ColorChooser value={color} onChange={setColor} colors={Colors} />

				<Label>Ícono</Label>
				<IconChooser value={icon} onChange={setIcon} iconNames={['fas fa-user', 'fas fa-user-ninja', 'fas fa-user-astronaut', 'fas fa-user-graduate', 'fas fa-user-secret']} icons={[faUser, faUserNinja, faUserAstronaut, faUserGraduate, faUserSecret]} />
			</Card>
		</Form>
	)
}

export default EditProfile;