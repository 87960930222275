import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {Label, Button, Input, LoadingPage} from '../../components';
import Auth from '../../services/Auth';
import '../../styles/Login.css';

function Login() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState(null);
	const [loadingPage, setLoadingPage] = useState(true);
	const [loadingContinue, setLoadingContinue] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams, ] = useSearchParams();

	function redirect(url){
		if (url[0] === '/') navigate(location.state?.redirect || url, {replace: true});
		else window.location.replace(url);
	}

	const login = () => {
		setLoadingContinue(true);
		setError(null);
		Auth.checkCredentials({
			email: email.trim().toLowerCase(),
			password: password
		}).then(data => {
			if (data.status === 'success'){
				Auth.signIn({
					authToken: Auth.AuthToken,
					userID: Auth.UserID
				}, searchParams.get('service'), searchParams.get('continue') ).then(data => {
					setLoadingContinue(false);
					if (data.status === 'success'){
						if (data.newDevice){
							navigate(`/auth/newdevice?${searchParams.toString()}`);
						} else {
							redirect(data.redirectURL);
						}
					} else {
						setError(data.data);
					}
				}).catch(e => {
					console.log(`Error al iniciar sesión: ${e}`);
				});	
			} else {
				setLoadingContinue(false);
				setError(data.data);
			}
		}).catch(e => {
			console.log(`Error al chequear credencials: ${e}`);
		});
	};

	useEffect(() => {
		Auth.checkSession(searchParams.get('service'), searchParams.get('continue')).then(data => {
			setLoadingPage(false);
			if (data.signedIn){
				redirect(data.redirectURL);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate]);

	if (loadingPage){
		return (
			<LoadingPage />
		);
	}

	return (
		<div id='login'>
			<p className='login-form__title'>Acceder</p>
			<p className='login-form__subtitle'>Ingresá tus datos para acceder</p>
			
			<div className='login-form'>
				<Label>Email</Label>
				<Input autoFocus={true} spellCheck={false} type="email" value={email} onChange={setEmail} icon={faEnvelope} placeholder="Ingresá tu email" error={error?.type === 'user'}/>
				{error?.type === 'user' ? <p className='input-error'>{error.msg}</p> : null}

				<Label>Contraseña</Label>
				<Input type="password" value={password} onChange={setPassword} onEnter={login} icon={faLock} placeholder="Ingresá tu contraseña" error={error?.type === 'password'}/>
				{error?.type === 'password' ? <p className='input-error'>{error.msg}</p> : null}
			</div>

			<Button title="Continuar" disabled={email === '' || password === ''} loading={loadingContinue} onClick={login}/>
			<div className="login__suggestion_wrapper">
				<p className="login__suggestion">¿No tenés una cuenta?</p>
				<Link to="/auth/signup">Registrate</Link>
			</div>
		</div>
	);
}

export default Login;
