import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>

    <ToastContainer
      position="bottom-center"
      transition={Slide}
      autoClose={5000}
      hideProgressBar={true}
      closeButton={false}
      style={{
        paddingBottom: 20
      }}
    />
  </StrictMode>
)

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    registration.waiting.postMessage({type: 'SKIP_WAITING'})
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      alert('Hay una nueva versión disponible')
      window.location.reload()
    })
  },
  onSuccess: () => {}
})