import { faGear, faKey, faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const sections = [
	{
		name: 'home',
		link: '/myaccount',
		icon: faHouse,
		title: 'Mi cuenta'
	},
	{
		name: 'passwords',
		link: '/passwords',
		icon: faKey,
		title: 'Contraseñas'
	},
	{
		name: 'settings',
		link: '/settings',
		icon: faGear,
		title: 'Opciones'
	}
]

function NavbarItem({selected, icon, title}){
	return (
		<div className={`section__navbar__item ${selected ? 'selected' : ''}`}>
			<FontAwesomeIcon icon={icon} className='section__navbar__item__icon'/>
			{selected ? <p className='section__navbar__item__title'>{title}</p> : null}
		</div>
	);
}

function Navbar(props){
	/*let templateColumns = '';

	for (let i = 0; i < sections.length; i++){
		if (props.sectionName === sections[i].name){
			templateColumns += 'auto ';
		} else {
			templateColumns += 'fit-content(0) ';
		}
	}*/

	return (
		<div className='section__navbar' /*style={{gridTemplateColumns: templateColumns}}*/>
			{
				sections.map(section => (
					<Link key={section.name} to={section.link}><NavbarItem icon={section.icon} selected={props.sectionName === section.name} title={section.title}/></Link>
				))
			}
		</div>
	);
}

export default Navbar;