import { faUser, faUserAstronaut, faUserGraduate, faUserNinja, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Auth from '../services/Auth';

const icons = {'fas fa-user': faUser, 'fas fa-user-ninja': faUserNinja, 'fas fa-user-astronaut': faUserAstronaut, 'fas fa-user-graduate': faUserGraduate, 'fas fa-user-secret': faUserSecret}

function ProfileIcon(props){
	return (
		<div className="profile-icon" style={{backgroundColor: Auth.UserData.icon.color}}>
			<FontAwesomeIcon icon={icons[Auth.UserData.icon.name]}/>
		</div>
	);
}

export default ProfileIcon;