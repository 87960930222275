import { useEffect, useState } from "react"
import {Card, Form, Input, Label, IconChooser} from "../../components"
import { useNavigate, useSearchParams } from "react-router-dom"
import API from "../../services/API"
import Auth from "../../services/Auth"
import { faDesktop, faMobileScreen, faMoneyCheck } from "@fortawesome/free-solid-svg-icons"

export default function EditDevice(){
	const [name, setName] = useState()
	const [icon, setIcon] = useState()
	const [loading, setLoading] = useState(false)

	const navigate = useNavigate()
	const [searchParams, ] = useSearchParams()

	useEffect(() => {
		const session = Auth.UserData.sessions.filter(s => s.HID === searchParams.get('HID'))[0]
		setName(session.deviceName)
		setIcon(session.deviceIcon)
	}, [searchParams])

	function save(){
		setLoading(true)
		API.UpdateDevice({HID: searchParams.get('HID'), deviceName: name, deviceIcon: icon}).then(data => {
			navigate(-1);
		})
	}

	function cancel(){
		navigate(-1)
	}

	return (
		<Form title="Editar dispositivo" saveTitle="Guardar cambios" onSubmit={save} loading={loading} cancelTitle="Cancelar" onCancel={cancel} disabled={name === ''}>
			<Label primary>Nombre</Label>
			<Input icon={faMoneyCheck} backgroundColor="white" type="name" value={name} onChange={setName} placeholder="Nombre del dispositivo" />

			<Label>Ícono</Label>
			<Card>
				<IconChooser value={icon} onChange={setIcon} iconNames={['fas fa-desktop', 'fas fa-mobile-screen']} icons={[faDesktop, faMobileScreen]} />
			</Card>
		</Form>
	)
}