import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef, useEffect } from "react";
import ReactLoading from 'react-loading';

const isTouchDevice = 'ontouchstart' in document.documentElement;

function SwipeButton({cancel = false, side = 'left', color = 'var(--theme-color)', title, cancelTitle, size = 55, loading = false, onSubmit = () => {}, disabled}){
	const [dragging, setDragging] = useState(false)
	const [sliderWidth, setSliderWidth] = useState(size)
	const [initialPos, setInitialPos] = useState(null)
	const [containerWidth, setContainerWidth] = useState(null)

	const sliderRef = useRef(null)
	const buttonRef = useRef(null)

	function absoluteToRelative(pointerX){
		return pointerX - buttonRef.current.getBoundingClientRect().left
	}

	function getProgress(width){
		return (width - size) / (containerWidth - size) * 100
	}

	function handleDragStart(relX){
		if (!loading && !disabled){
			setDragging(true)
			setInitialPos(relX)
			setContainerWidth(buttonRef.current.getBoundingClientRect().width)
		}
	}

	function handleDragMove(relX){
		if (dragging && !disabled){
			const newWidth = (side === 'left' ? relX - initialPos : initialPos - relX) + size
			const newProgress = getProgress(newWidth)
			if (newProgress >= 0 && newProgress <= 100){
				if (newProgress >= 90){
					setDragging(false)
					setSliderWidth(size)
					onSubmit()
				} else {
					setSliderWidth(newWidth)
				}
			}
		}
	}

	function handleDragEnd(){
		if (dragging && !disabled){
			setDragging(false)
			setInitialPos(null)
			setSliderWidth(size)
		}
	}

	function mouseDown(e){
		if (!isTouchDevice){
			e.stopPropagation();
			handleDragStart(absoluteToRelative(e.clientX));
		}
	}

	function mouseMove(e){
		if (!isTouchDevice){
			e.stopPropagation();
			handleDragMove(absoluteToRelative(e.clientX));
		}
	}

	function mouseLeave(e){
		if (!isTouchDevice){
			e.stopPropagation();
			handleDragEnd();
		}
	}

	function mouseUp(e){
		if (!isTouchDevice){
			e.stopPropagation();
			handleDragEnd();
		}
	}

	function touchStart(e){
		if (isTouchDevice){
			e.stopPropagation();
			handleDragStart(absoluteToRelative(e.targetTouches[0].clientX))
		}
	}

	function touchMove(e){
		if (isTouchDevice){
			e.stopPropagation();
			handleDragMove(absoluteToRelative(e.targetTouches[0].clientX));
		}
	}

	function touchEnd(e){
		if (isTouchDevice){
			e.stopPropagation();
			handleDragEnd();
		}
	}

	function contextMenu(e){
		e.stopPropagation();
		e.preventDefault();
	}

	useEffect(() => {
		if (isTouchDevice){
			const refCopy = sliderRef.current;
			let contextMenuEvent = refCopy.addEventListener('contextmenu', contextMenu, {passive: false});

			return () => {
				refCopy.removeEventListener('contextmenu', contextMenuEvent);
			};
		}
	}, []);

	return (
		<div
			ref={buttonRef}
			className={`swipe-button ${disabled && !cancel ? 'disabled' : ''} ${cancel ? 'cancel' : ''} ${cancel && !dragging ? 'form-title-mode' : ''}`}
			style={{
				height: size,
				paddingLeft: (cancel && !dragging) ? 20 : side === 'left' ? size : 0,
				paddingRight: side === 'right' ? size : 0,
				transition: dragging ? '' : 'all 0.2s'
			}}
		>
			<div
				ref={sliderRef}
				className="swipe-button__slider"
				style={{
					height: size,
					width: loading ? containerWidth : dragging ? sliderWidth : size,
					paddingLeft: loading ? 0 : side === 'left' ? sliderWidth - size : 0,
					paddingRight: loading ? 0 : side === 'right' ? sliderWidth - size : 0,
					backgroundColor: color,
					left: side === 'left' ? 0 : 'auto',
					right: side === 'right' ? 0 : 'auto',
					transition: dragging ? '' : 'all 0.2s'
				}}
				onMouseDown={mouseDown}
				onMouseMove={mouseMove}
				onMouseLeave={mouseLeave}
				onMouseUp={mouseUp}
				onTouchStart={touchStart}
				onTouchMove={touchMove}
				onTouchEnd={touchEnd}
			>
				{ loading ? 
					<ReactLoading type="spin" color="white" height={size / 2} width={size / 2} /> :
					<div className="swipe-button__icon-wrapper">
						<FontAwesomeIcon icon={side === 'right' ? faChevronLeft : faChevronRight} className='swipe-button__icon-wrapper__icon' style={{opacity: side === 'left' ? '25%' : '100%'}}/>
						<FontAwesomeIcon icon={side === 'right' ? faChevronLeft : faChevronRight} className='swipe-button__icon-wrapper__icon' style={{opacity: '50%'}}/>
						<FontAwesomeIcon icon={side === 'right' ? faChevronLeft : faChevronRight} className='swipe-button__icon-wrapper__icon' style={{opacity: side === 'left' ? '100%' : '25%'}}/>
					</div>
				}
			</div>
			{ (!cancel || dragging) ? 
				<p className="swipe-button__title" style={{color: color}}>{cancel ? cancelTitle : title}</p> :
				<p className="form__title">{title}</p>
			}		
		</div>
	)
}

export default SwipeButton;