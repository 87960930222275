import { useState } from "react"
import VaultHandler from "../../services/VaultHandler"
import { Section, SectionContent, Topbar, Navbar } from "../../components/section"
import { Card, Input, Button } from "../../components"
import Auth from "../../services/Auth"
import { faLock } from "@fortawesome/free-solid-svg-icons"

export default function KeyRequest({setVault}){
	const [masterKey, setMasterKey] = useState(process.env.NODE_ENV === 'development' ? '1234' : '')
	const [unlockLoading, setUnlockLoading] = useState(false)
	const [incorrectPassword, setIncorrectPassword] = useState(false)

	function handleMasterKeySubmit(){
        if (masterKey !== ''){
            VaultHandler.generateVaultKey(masterKey).then(() => {
                setVault(VaultHandler.getDecryptedVault(Auth.UserData.passwords))
                setUnlockLoading(false)
            }).catch(e => {
                console.log(e)
                setIncorrectPassword(true)
            })
        }
	}

	return (
		<Section>
			<Topbar title="Contraseñas" />	

			<SectionContent id="passwords__master-key-request">
				<Card>
					<p style={{fontSize: 24, marginBottom: 15, color: 'var(--primary-text-color)'}}>Clave maestra</p>
					<Input type="password" icon={faLock} value={masterKey} onChange={setMasterKey} onEnter={handleMasterKeySubmit} />
					{
						incorrectPassword ? <p style={{color: 'var(--red)'}}>Contraseña incorrecta</p> : null
					}
				</Card>

				<div style={{gridArea: 'bottom'}}>
					<Button title="Desbloquear" onClick={handleMasterKeySubmit} loading={unlockLoading} disabled={masterKey === ''} />
				</div>
			</SectionContent>

			<Navbar sectionName="passwords" />
		</Section>
	)
}