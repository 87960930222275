import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { SectionContent, Topbar } from '../../components/section';
import Section from '../../components/section/Section';
import {SwipeButton} from '../../components';
import totp from 'totp-generator';
import Auth from '../../services/Auth';

export default function DeviceID(){
	const [code, setCode] = useState('');
	const [expiresIn, setExpiresIn] = useState();
	const [deviceIDLoading, setDeviceIDLoading] = useState(false);

	const navigate = useNavigate();

	function update(){
		setCode(totp(Auth.UserData.settings.deviceID.token));
		setExpiresIn(30 - (new Date().getSeconds() % 30));
	}

	function handleRemoveDeviceID(){
		setDeviceIDLoading(true);
		Auth.updateDeviceIDStatus('pending').then(data => {
			setDeviceIDLoading(false);
			navigate('/myaccount');
		}).catch(() => {});
	}

	useEffect(() => {
		setCode(totp(Auth.UserData.settings.deviceID.token));
		update();
		setInterval(update, 1000);
	}, []);

	return (
		<Section>
			<Topbar backURL="/myaccount" title="Device ID" />

			<SectionContent id="deviceid">
				<div style={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
					<div className='deviceid__code-wrapper'>
						<p style={{color: 'var(--primary-text-color)', fontSize: 20, marginBottom: 10}}>Código</p>
						<div className='deviceid__code'>
							{
								code.split('').map((digit, i) => (
									<div className='deviceid__code__digit'>
										{digit}
									</div>
								))
							}
						</div>
					</div>
					
					<p style={{color: 'var(--promary-text-color)', fontSize: 24, marginBottom: 10}}>{`Expira en ${expiresIn >= 60 ? '1' : '0'}:${('0' + expiresIn % 60).slice(-2)}`}</p>
					<p style={{color: 'var(--red)'}}>No compartas este código con nadie</p>
				</div>

				<SwipeButton color="var(--red)" title="Dejar de usar este dispositivo para Device ID" onSubmit={handleRemoveDeviceID} loading={deviceIDLoading} />
			</SectionContent>
		</Section>
	);
}