import React, { cloneElement, forwardRef, useEffect, useImperativeHandle, useRef } from "react"

const SwipeActionProvider = forwardRef(({children}, ref) => {
    const callbacks = useRef([])

	function setCallback(fn, identifier){
        if (fn !== null){
            callbacks.current.push(fn)
            return callbacks.current.length - 1
        } else {
            callbacks.current.splice(identifier, 1)
        }
	}

    function resetSliders(except = null){
        callbacks.current.forEach((cb, index) => {
			if (index !== except){ cb({type: 'reset'}) }
		});
	}

    useImperativeHandle(ref, () => ({
        resetSliders: resetSliders,
        expand: (index, button) => {callbacks.current[index]({type: 'expand', button: button})}
    }))

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)){
            return cloneElement(child, {
                setCallback: setCallback,
                onDrag: index => resetSliders(index)
            })
        }
        return child
    })

    return <>{childrenWithProps}</>
})

export default SwipeActionProvider