function Card({children, title, subtitle}){
	return (
		<div className='card'>
			{title ? <p className="card__title">{title}</p> : null}
			{subtitle ? <p className="card__subtitle">{subtitle}</p> : null}
			{children}
		</div>
	)
}

export default Card;