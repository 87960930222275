import { faArrowRightFromBracket, faDesktop, faMobileScreen, faPencil, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import {ActionSheet, Button, Card, ProfileIcon, SwipeActions, SwipeActionProvider} from '../../components';
import { ActionSheetButton } from '../../components/ActionSheet';
import { Section, Navbar, SectionContent, Topbar } from '../../components/section';
import Auth from '../../services/Auth';

const deviceIcons = {'fas fa-mobile-screen': faMobileScreen, 'fas fa-desktop': faDesktop}

function DeviceItem({data, index, identifier, onEdit, onSignOut, containerClass, contentClass, setCallback, onDrag}){
	const date = new Date(data.lastConection)
	return (
		<SwipeActions
			identifier={identifier}
			height={data.HID === Auth.UserData.settings.deviceID.authenticatorHID ? 63 : 53}
			buttonWidth={80}
			containerClass={containerClass}
			contentClass={contentClass}
			setCallback={setCallback}
			onDrag={onDrag}
			rightButtons={[
				{
					color: 'var(--blue)',
					icon: faPencil,
					onClick: () => {
						onEdit(data.HID)
					}
				},
				!(data.HID !== Auth.UserData.currentHID && data.HID === Auth.UserData.settings.deviceID.authenticatorHID) ? {
					color: 'var(--red)',
					icon: faArrowRightFromBracket,
					onClick: (index, button) => {
						onSignOut(data, index, button)
					}
				} : null
			].filter(item => item !== null)}
		>
			<FontAwesomeIcon icon={deviceIcons[data.deviceIcon]} className='account__device-list__item__content__icon' />
			<div className='account__device-list__item__vertical-wrapper'>
				<div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
					<p style={{color: 'var(--primary-text-color)', fontSize: 18}}>{data.deviceName}</p>
					{Auth.UserData.currentHID === data.HID ? <FontAwesomeIcon icon={faCircleCheck} style={{color: 'var(--theme-color)'}}/> : null}
				</div>
				
				<p style={{color: 'var(--secondary-text-color)'}}>
					{`${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} - ${('0'+date.getHours()).slice(-2)}:${('0'+date.getMinutes()).slice(-2)}`}
				</p>

				{data.HID === Auth.UserData.settings.deviceID.authenticatorHID ? <p style={{color: 'var(--theme-color)'}}>Device ID</p> : null}
			</div>
		</SwipeActions>									
	)
}

function MyAccount(){
	const [sessions, setSessions] = useState(Auth.UserData.sessions)
	const [signOutSession, setSignOutSession] = useState(null)

	const navigate = useNavigate()

	const swipeListRef = useRef()
	const signOutActionSheetRef = useRef()

	function onEdit(HID){
		navigate(`/myaccount/device/edit?HID=${HID}`)
	}

	function onSignOut(data, index, button){
		swipeListRef.current.expand(index, button)
		setSignOutSession(data)
		signOutActionSheetRef.current.open()
	}

	function handleSignOut(){
		signOutActionSheetRef.current.close();
		Auth.signOut(signOutSession.HID).then(res => {
			if (!Auth.isAuthenticated()){
				navigate('/auth/signin')
			}
			setSessions(Auth.UserData.sessions)
		})
	}

	return (
		<Section>
			<Topbar title="Mi Cuenta">
				{Auth.thisDeviceIsAuthenticator ? <Link to="/deviceid"><p style={{fontSize: 18}}>Device ID</p></Link> : null}
			</Topbar>

			<SectionContent id="account">
				<Card>
					<div className='account__profile-card__info-horizontal-wrapper'>
						<ProfileIcon />
						<div className='account__profile-card__info-wrapper__info-vertical-wrapper'>
							<p style={{color: 'var(--primary-text-color)', fontSize: 20}}>{Auth.UserData.name}</p>
							<p style={{color: 'var(--secondary-text-color)'}}>{Auth.UserData.email}</p>
						</div>
					</div>

					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<Link to="/myaccount/edit"><Button ghost title="Editar perfil" /></Link>
					</div>
				</Card>

				<Card title="Mis dispositivos" subtitle="Tu cuenta está activa en estos dispositivos">
					<div className='account__device-list'>
						<SwipeActionProvider ref={swipeListRef}>
							{sessions.sort((a, b) => new Date(b.lastConection) - new Date(a.lastConection)).map((session, i) => (
								<DeviceItem
									key={session.HID}
									index={i}
									onEdit={onEdit}
									onSignOut={onSignOut}
									data={session}
									containerClass='account__device-list__item'
									contentClass='account__device-list__item__content'
									buttonWidth={80}
								/>
							))}
						</SwipeActionProvider>
					</div>
				</Card>
			</SectionContent>

			<Navbar sectionName="home" />

			<ActionSheet reference={signOutActionSheetRef} title={`Se cerrará sesión en ${signOutSession?.deviceName}`} cancelTitle="Cancelar" onClose={() => {swipeListRef.current.resetSliders()}}>
				<ActionSheetButton title="Cerrar sesión" color='var(--dark-red)' onClick={handleSignOut} />
			</ActionSheet>
		</Section>
	);
}

export default MyAccount