import { faChevronLeft, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Input from '../Input';

function Topbar({title, children, backURL = null, onBack = null, search = false, searchValue = '', onSearch = () => {}, searchMode = false, setSearchMode = () => {}}){
	function handleCancelSearch(){
		setSearchMode(false)
		onSearch('')
	}

	return (
		<div className={`topbar ${searchMode ? 'search-mode' : ''}`}>
			<div className={`topbar__top ${searchMode ? 'search-mode' : ''}`}>
				{backURL ? <Link to={backURL}><FontAwesomeIcon icon={faChevronLeft} style={{color: 'var(--primary-text-color)', fontSize: 20, marginRight: 10}}/></Link> :
				onBack ? <FontAwesomeIcon icon={faChevronLeft} style={{color: 'var(--primary-text-color)', fontSize: 20, marginRight: 10}} onClick={onBack}/> : null}
				<p className='topbar__title'>{title}</p>
				<div className='topbar__buttons'>
					{children}
				</div>
			</div>
			{ search ?
				<div className={`topbar__bottom ${searchMode ? 'search-mode' : ''}`}>
					<Input compact type="text" icon={faMagnifyingGlass} iconColor="var(--secondary-text-color)" value={searchValue} onChange={onSearch} placeholder="Buscar contraseñas" onFocus={() => {setSearchMode(true)}} onBlur={() => {setSearchMode(false)}}/>
					<p className={`topbar__bottom__cancel ${searchMode ? 'search-mode' : ''}`} onClick={handleCancelSearch}>Cancelar</p>
				</div> : null
			}
		</div>
	);
}

export default Topbar;