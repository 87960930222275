import SwipeButton from "./SwipeButton";

function Form({children, title, saveTitle, disabled, onSubmit = () => {}, cancelTitle, loading, onCancel = () => {}}){
	return (
		<div className='form'>
			<SwipeButton cancel title={title} cancelTitle={cancelTitle} color="var(--red)" side="right" onSubmit={onCancel} disabled={loading}/>

			<div className='form__content'>
				{children}
			</div>

			<SwipeButton title={saveTitle} color="var(--theme-color)" onSubmit={onSubmit} loading={loading} disabled={disabled} />
		</div>
	);
}

export default Form;