import { useState } from "react"
import { Form, Label, Input } from "../../components"
import { faFolder } from "@fortawesome/free-solid-svg-icons"

export default function NewFolder({newFolder, cancel}){
	const [name, setName] = useState('')
	const [loading, setLoading] = useState(false)
	const [nameError, setNameError] = useState(false)

	function handleSubmit(){
		setLoading(true)
		newFolder({name: name.trim()}).catch(() => {
			setLoading(false)
			setNameError(true)
		})
	}

	return (
		<Form
			title="Nueva carpeta"
			saveTitle="Guardar"
			onSubmit={handleSubmit}
			loading={loading}
			cancelTitle="Cancelar"
			onCancel={cancel}
			disabled={name === ''}
		>
			<Label primary>Nombre</Label>
			<Input icon={faFolder} backgroundColor='white' value={name} onChange={setName} placeholder="Nombre de la carpeta" />
			{ nameError ? <p style={{color: 'var(--red)'}}>Ese nombre ya fue usado</p> : null }
		</Form>
	);
}