import React from 'react';

function SectionContent(props){
	return (
		<div className='section__content' id={props.id}>
			{props.children}
		</div>
	);
}

export default SectionContent;